import React from "react";
import CeWrapper from "../../../global/CeWrapper";
import QuickSearchWidget from "../../../../../jobs/components/widgets/QuickSearchWidget/QuickSearchWidget";

const ce = ({ id, ceData }) => {
  return (
    <CeWrapper ceData={ceData} renderHeader={false}>
      <QuickSearchWidget
        id={id}
        header={ceData.headerParts?.header?.title}
        subHeader={ceData.headerParts?.subheader?.subheader?.title}
        bgImages={ceData.contentParts.images}
        searchConfigs={ceData.contentParts.searchConfigs}
      />
    </CeWrapper>
  );
};

export default ce;
