import styles from "./QuickSearchWidget.module.scss";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import Router from "next/router";
import { connect } from "react-redux";
import { fetchJobSearch } from "../../../operations/search";
import JobsApiService, {
  fetchJobsCountByTimeLimits,
} from "../../../services/JobsApiService";
import useSWR from "swr";
import ApiImage from "../../../../cms_content/components/global/ApiImage";
import getConfig from "next/config";

const QuickSearchWidget = ({
  id,
  header,
  subHeader,
  bgImages = [],
  searchConfigs,
  fetchJobSearch,
}) => {
  const { publicRuntimeConfig } = getConfig();
  /**
   * map filter keys of search config to filter keys of search api
   *
   * {filter: cities: ['546', '256']}
   *      to
   * {filter: geodatas: ['546', '256']}
   *
   * @param itemSearchConfig
   * @returns {*}
   */
  const mapSearchConfigToSearchArgs = (itemSearchConfig) => {
    const whiteListParams = ["filter"];

    let searchConfig = {};
    for (let configKey in itemSearchConfig) {
      if (configKey in whiteListParams) {
        searchConfig[configKey] = itemSearchConfig[configKey];
      }
    }

    // map filter key of search config to api filter keys
    const filterMapping = {
      cities: "geodatas",
    };

    const mappedFilterConfig = {};

    for (let filterKey in searchConfig.filter) {
      const filterValue = searchConfig.filter[filterKey];

      let newFilterKey = "";
      if (filterKey in filterMapping) {
        newFilterKey = filterMapping[filterKey];
      } else {
        newFilterKey = filterKey;
      }

      mappedFilterConfig[newFilterKey] = filterValue;
    }

    searchConfig.filter = mappedFilterConfig;

    return searchConfig;
  };

  const doSearchByFilter = (searchConfig, e) => {
    e.preventDefault();
    const jobSearchlLink = PagePathUtility.getPathConfig("jobSearch");

    fetchJobSearch(searchConfig).then(() => {
      Router.push(jobSearchlLink);
      window.scroll({
        top: 0,
        left: 0,
      });
    });
  };

  /**
   * Fetch job counts of search configs
   *
   * @param url
   * @param searchConfigIndex
   * @returns {Promise<*|number>}
   */
  const fetcher = async (url, searchConfigIndex) => {
    const searchConfig = mapSearchConfigToSearchArgs(
      searchConfigs[searchConfigIndex]
    );

    const res = await fetchJobsCountByTimeLimits(0, 0, searchConfig);
    return res.result?.solr?.numFound ?? 0;
  };

  return (
    <div className={styles.quickSearchWidget}>
      <div className={styles.text}>
        {header && <h2 className={styles.headline}>{header}</h2>}
        {subHeader && <p>{subHeader}</p>}
      </div>
      <div className={styles.gallery}>
        {bgImages &&
          bgImages.map((image, index) => (
            <ApiImage
              key={index}
              id={image}
              lazy={true}
              className={`${styles.img}`}
            />
          ))}
      </div>
      <div className={styles.cities}>
        {searchConfigs &&
          searchConfigs.map((searchConfigItem, index) => {
            const searchConfig = mapSearchConfigToSearchArgs(searchConfigItem);

            const { data: jobCount, error } = useSWR(
              [`/jobs-counts/${id}`, index],
              fetcher,
              publicRuntimeConfig.globalSwrConfig
            );

            return (
              <div className={styles.circleContent} key={index}>
                <a onClick={doSearchByFilter.bind(this, searchConfig)}>
                  <h4 className={styles.city}>{searchConfigItem.label}</h4>
                  {jobCount && (
                    <span className={styles.jobs}>{jobCount} Jobs</span>
                  )}
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchJobSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchWidget);
